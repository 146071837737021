/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment-timezone';

moment.tz.setDefault("Etc/GMT-1");

export const columns = (goToCompany: any, deleteDrop: any, studentsLiked: any) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '200px',
    sorter: true,

    render: (text: string, record: any) => {
      return (
        <div
          style={{textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => {
            goToCompany(record.id);
          }}>
          {text}
        </div>
      );
    },
  },
  {
    title: 'Cities',
    dataIndex: 'cities',
    key: 'cities',
    width: '150px',
    sorter: true,
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <span key={id}>{item + '\t'}</span>;
      });
    },
  },
  {
    title: 'Sectors',
    dataIndex: 'sectors',
    key: 'sectors',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name_en}</div>;
      });
    },
  },
  {
    title: 'Company Size',
    dataIndex: 'company_size',
    key: 'company_size',
    width: '150px',
    render: (value: any) => {
      return <div>{value?.name}</div>;
    },
    // sorter: true,
  },
  {
    title: 'Perks',
    dataIndex: 'emoji',
    key: 'emoji',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <span key={id}>{item.name + '\t'}</span>;
      });
    },
  },
  // {
  //   title: 'Tags',
  //   dataIndex: 'tags',
  //   key: 'tags',
  //   width: '150px',
  //   // sorter: true,
  //   render: (value: any) => {
  //     if (value) {
  //       return <div>{value[0].name}</div>;
  //     } else {
  //       return <div>{value}</div>;
  //     }
  //   },
  // },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: '250px',
    // sorter: true,
    sorter: true,
    render: (value: any) => {
      return <div>{moment(value).format('YYYY-MM-DD HH:mm')}</div>;
    },
  },
  {
    title: 'Current opportunities',
    dataIndex: 'opportunities_total',
    key: 'opportunities_total',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Current events',
    dataIndex: 'events_total',
    key: 'events_total',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Current likes',
    dataIndex: 'likes_total',
    key: 'likes_total',
    width: '150px',
    sorter: true,
  
    render: (text: string, record: any) => {
      return (
        <div
          style={{textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => {
            studentsLiked({id: "" + record.id, type: '1'});
          }}>
          {text}
        </div>
      );
    },
  },
  {
    title: 'Number of visits',
    dataIndex: 'visits_total',
    key: 'visits_total',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Feed appearances',
    dataIndex: 'feed_appearances',
    key: 'feed_appearances',
    width: '150px',
    sorter: true,
  },
  {
    width: '9rem',
    title: '',
    dataIndex: 'operation',
    render: (_: any, record: any) => {
      return (
        <div
          style={{
            appearance: 'none',
            height: '3rem',
            border: 'none',
            display: 'flex',
            fontSize: '1.4rem',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#F65532',
            color: 'white',
            width: '6rem',
            cursor: 'pointer',
            marginRight: '8px',
          }}
          onClick={() => {
            deleteDrop(record.id);
          }}>
          Delete
        </div>
      );
    },
  },
];
