/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment-timezone';

moment.tz.setDefault("Etc/GMT-1");

export const columns = (goToStudents: any, openPopupMessage: any) => [
  {
    title: 'Date sent',
    dataIndex: 'message_created',
    key: 'message_created',
    width: '230px',
    render: (value: any) => {
      return <div>{moment(value).format('YYYY-MM-DD HH:mm')}</div>;
    }
    // sorter: true,
  },

  {
    title: 'Message content',
    dataIndex: 'text',
    key: 'text',
    width: '250px',
    // sorter: true,
    render: (value: any) => {
      return (
        <div
          onClick={() => openPopupMessage(value)}
          style={{
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          {value}
        </div>
      );
    },
  },
  {
    title: 'Sent from',
    dataIndex: 'name',
    key: 'name',
    width: '150px',
    // sorter: true,
  },

  {
    title: 'Type of message',
    dataIndex: 'message_type',
    key: 'message_type',
    width: '150px',
    // sorter: true,
    render: (value: any) => {
      switch (value) {
        case '1':
          return <div>Company</div>;
        case '2':
          return <div>Opportunity</div>;
        case '3':
          return <div>Event</div>;
      }
    },
  },
  {
    title: 'Number sent',
    dataIndex: 'count',
    key: 'count',
    width: '150px',
    // sorter: true,
  },

  {
    title: 'Recipients',
    dataIndex: 'account_ids',
    key: 'account_ids',
    width: '150px',
    render: (value: any, data: any) => {
      return (
        <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => goToStudents(value, data.id)}>
          View students
        </div>
      );
    },
  },
];
