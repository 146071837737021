/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {Portal} from 'react-portal';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {AppState} from 'store';
//Actions
import {CompanyActionsRedux} from 'store/companyDetails/actions';
//GraphQl
import {useMutation, useQuery} from '@apollo/client';
import {
  CREATE_COMPANY,
  UPLOAD_BACKGROUND,
  UPLOAD_BANNER,
  UPLOAD_LOGO,
  UPDATE_COMPANY,
  COMPANY_PROMOTION,
} from 'requests/mutations';
import {GET_COMPANY_OPTIONS, GET_COMPANY} from 'requests/queries';
//Styling
import {useStyles} from '../defaultStylesSinglePage';
//Components
import {DetailsBlock, DetailsBlock2, UploadPhotos, TargetBlock} from 'components/CompanyDetails';
import PageWithDrawer from 'components/PageWithDrawer';
import {SuccessModal} from 'components/Modals';
import LoadingComponent from 'components/LoadingComponent';
import Loader from 'components/UI/Loader';
import {Form, Button} from 'antd';
//Functions, utils
import {NotificationTypes, showNotification} from 'utils/notifications';
import {urlTofile} from 'utils/urlTofile';
import {handleChangeImage, closeModal, catchError} from 'containers/defaultFunc';
import moment from 'moment-timezone';
import OpportunityEventLists from 'components/CompanyDetails/OpportunityEventLists';
import {createSocialUrl} from 'utils/createSocialUrl';

const mapSelectors = (selectors: any) =>
  selectors?.map((item: any) => {
    return item.id;
  });

moment.tz.setDefault('Etc/GMT-1');
const dateFormat = 'YYYY/MM/DD';

const CompanyPage: FC = () => {
  const styles = useStyles();
  const history: any = useHistory();

  const states: any = history.location.state;

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const idCompany: any = useSelector((state: AppState) => state.companyDetails.id) ?? '';

  const [addPromo, setAddPromo] = useState(false);
  const [id, setId] = useState(idCompany);
  const [startEnd, setstartEnd] = useState([moment(), moment()]);
  const [loader, setLoader] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  // photos
  const [backgroundCache, setBackgroundCache] = useState('');
  const [background, setBackground] = useState<any>('');
  const [logoCache, setLogoCache] = useState<any>('');
  const [logo, setLogo] = useState<any>('');
  const [bannerCache, setBannerCache] = useState<any>('');
  const [banner, setBanner] = useState<any>('');

  const [item, setItem] = useState<any>('');
  const [isUpdatePage, setIsUpdatePage] = useState(false);
  const [activePromo, setActivePromo] = useState(false);

  const [sectors, setSectors] = useState([]);
  const [emojis, setEmojis] = useState([]);
  const [cities, setCities] = useState([]);

  const [archetypes, setArchetypes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [profileInterests, setProfileInterests] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [grades, setGrades] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [internationalExperiences, setInternationalExperiences] = useState([]);
  const [activities, setActivities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [workingExperiences, setWorkingExperiences] = useState([]);

  const [forSetCompanyOptionsData, setCompanyOptionsData] = useState({
    companySectors: [],
    companyEmojis: [],
    // companyTags: [],
    companyCompanySizes: [],
    availableCities: [],
  });

  const {data: companyOptionsData, error: errorOptions} = useQuery(GET_COMPANY_OPTIONS);
  const {data: companyMain, error} = useQuery(GET_COMPANY, {
    variables: {id: String(id)},
    skip: id === null || id === '',
  });

  const [createCompany] = useMutation(CREATE_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const [uploadBackground] = useMutation(UPLOAD_BACKGROUND);
  const [uploadLogo] = useMutation(UPLOAD_LOGO);
  const [uploadBanner] = useMutation(UPLOAD_BANNER);
  const [setCompanyPromo] = useMutation(COMPANY_PROMOTION);

  const setLogoOp = (image: string) => {
    setLogoCache(image);
    setLogo(image);
  };

  const setBackgroundOp = (image: string) => {
    setBackgroundCache(image);
    setBackground(image);
  };

  const setBannerOp = (image: string) => {
    setBannerCache(image);
    setBanner(image);
  };

  useEffect(() => {
    setIsUpdatePage(window.location.pathname === '/companies/details');
  }, []);

  useEffect(() => {
    if (isUpdatePage) {
      if (companyMain) {
        const {company} = companyMain;

        dispatch(CompanyActionsRedux.setEntity(company));
        dispatch(CompanyActionsRedux.setEntityId(company.id));
        form.setFieldsValue({
          [`website`]: company.website,
          [`name`]: company.name,
          [`cities`]: company.cities,
          [`company_size`]: company.company_size?.id,
          [`bio_en`]: company.bio_en,
          [`bio_fr`]: company.bio_fr || '',
          [`bio_nl`]: company.bio_nl || '',
          // [`tags`]: company.tags[0].id,
          [`additional_info_en`]: company.additional_info_en,
          [`additional_info_fr`]: company.additional_info_fr,
          [`additional_info_nl`]: company.additional_info_nl,
          [`sectors`]: mapSelectors(company.sectors),
          [`emoji`]: mapSelectors(company.emoji),
          [`target_archetypes`]: mapSelectors(company.target_archetypes),
          [`target_education_levels`]: mapSelectors(company.target_education_levels),
          [`target_profile_interests`]: mapSelectors(company.target_profile_interests),
          [`target_degrees`]: mapSelectors(company.target_degrees),
          [`target_average_grades`]: mapSelectors(company.target_average_grades),
          [`target_achievements`]: mapSelectors(company.target_achievements),
          [`target_international_experiences`]: mapSelectors(company.target_international_experiences),
          [`target_activities`]: mapSelectors(company.target_activities),
          [`target_languages`]: mapSelectors(company.target_languages),
          [`target_work_experiences`]: mapSelectors(company.target_work_experiences),
          [`bonus_feed_points`]: company.bonus_feed_points,
        });
        if (company.socials) {
          form.setFieldsValue({
            [`Facebook`]: company.socials.find((s: {type: string; value: string}) => s.type === 'Facebook')?.value,
            [`Instagram`]: company.socials.find((s: {type: string; value: string}) => s.type === 'Instagram')?.value,
            [`Twitter`]: company.socials.find((s: {type: string; value: string}) => s.type === 'Twitter')?.value,
            [`Linkedin`]: company.socials.find((s: {type: string; value: string}) => s.type === 'Linkedin')?.value,
          });
        }

        setBackground(company.background_photo);
        setLogo(company.logo);
        setBanner(company.banner_photo);
        if (company.published_from && company.published_to) {
          setstartEnd([moment(company.published_from), moment(company.published_to)]);
          setAddPromo(true);
          const newD = moment(moment.now()).format(dateFormat);
          const start = moment(moment(company.published_from).format(dateFormat)).isSameOrBefore(newD);
          const end = moment(moment(company.published_to).format(dateFormat)).isSameOrAfter(newD);
          if (start && end) {
            setActivePromo(true);
          } else {
            setActivePromo(false);
          }
        } else {
          setAddPromo(false);
        }
      }
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [form, companyMain, isUpdatePage]);

  useEffect(() => {
    if (isUpdatePage) {
      id && history.replace(history.location.pathname, {...history.location.state, id});
      setId(!!history.location.state && states.id);
    }
  }, [history, isUpdatePage]);

  useEffect(() => {
    if (companyOptionsData) {
      const newSectors = companyOptionsData.companySectors.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });

      setSectors(newSectors);

      const newArchetypes = companyOptionsData.availableArchetypes?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setArchetypes(newArchetypes);
      const newEducationLevels = companyOptionsData.availableEducationLevels?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setEducationLevels(newEducationLevels);
      const newProfileInterests = companyOptionsData.availableInterests?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setProfileInterests(newProfileInterests);
      const newDegrees = companyOptionsData.availableDegrees?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setDegrees(newDegrees);
      const newGrades = companyOptionsData.availableEducationalGrades?.map((item: any) => {
        return {title: item.name, value: item.id};
      });
      setGrades(newGrades);
      const newAchievements = companyOptionsData.availableAchievements?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setAchievements(newAchievements);
      const newInternationalExperiences = companyOptionsData.availableInternationalExperiences?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setInternationalExperiences(newInternationalExperiences);
      const newActivities = companyOptionsData.availableActivities?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setActivities(newActivities);
      const newLanguages = companyOptionsData.availableLanguages?.map((item: any) => {
        return {title: item.name, value: item.id};
      });
      setLanguages(newLanguages);
      const newWorkingExperiences = companyOptionsData.availableWorkingExperiences?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setWorkingExperiences(newWorkingExperiences);

      const newEmojis = companyOptionsData.companyEmojis?.map((item: any) => {
        return {title: item.name + ' ' + item.value_en, value: item.id};
      });
      setEmojis(newEmojis);

      const newCities = companyOptionsData.availableCities?.map((item: any) => {
        return {title: item.name, value: item.name};
      });
      setCities(newCities);

      setCompanyOptionsData(companyOptionsData);
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [companyOptionsData]);

  const onCalendarChange = (dateString: any) => {
    if (dateString[0] === '' || dateString[1] === '') {
      setstartEnd([moment(), moment()]);
    } else {
      setstartEnd(dateString);
    }
  };
  const dataUpdateCreateCompany = (data: any) => {
    const socials = [];

    data.Facebook && socials.push({type: 'Facebook', value: createSocialUrl(data.Facebook, 'facebook')});
    data.Instagram && socials.push({type: 'Instagram', value: createSocialUrl(data.Instagram, 'instagram')});
    data.Twitter && socials.push({type: 'Twitter', value: createSocialUrl(data.Twitter, 'twitter')});
    data.Linkedin && socials.push({type: 'Linkedin', value: createSocialUrl(data.Linkedin, 'linkedin')});

    return {
      name: data.name,
      website: data.website,
      cities: data.cities,
      bio_en: data.bio_en,
      bio_fr: data.bio_fr || '',
      bio_nl: data.bio_nl || '',
      additional_info_en: data.additional_info_en,
      additional_info_fr: data.additional_info_fr,
      additional_info_nl: data.additional_info_nl,
      emoji: data.emoji.map((item: any) => {
        return item.key || item;
      }),
      target_archetypes: data.target_archetypes,
      target_education_levels: data.target_education_levels,
      target_profile_interests: data.target_profile_interests,
      target_degrees: data.target_degrees,
      target_average_grades: data.target_average_grades,
      target_achievements: data.target_achievements,
      target_international_experiences: data.target_international_experiences,
      target_activities: data.target_activities,
      target_languages: data.target_languages?.map((i: number) => i.toString()),
      target_work_experiences: data.target_work_experiences,
      bonus_feed_points: Number(data.bonus_feed_points),
      // tags: [data.tags],
      // tags: [],
      socials: socials,
      sectors: data.sectors,
      company_size: data.company_size,
    };
  };

  const onSubmit = async (data: any) => {
    if (data.target_archetypes.length < 1) {
      showNotification(NotificationTypes.info, 'Select at least 1 target archetype');
    } else if (data.emoji.length > 3) {
      showNotification(NotificationTypes.info, 'Perk max length is 3');
    } else if (logo === '' || logo === null || logo.includes('data:')) {
      showNotification(NotificationTypes.info, 'Logo is required, please select image or crop');
    } else if (background === '' || background === null || background.includes('data:')) {
      showNotification(NotificationTypes.info, 'Backgroud photo is required, please select image or crop');
    } else {
      try {
        setLoader(true);
        let response;
        if (isUpdatePage) {
          response = await updateCompany({
            variables: {
              id: id.toString(),
              ...dataUpdateCreateCompany(data),
            },
          });
        } else {
          response = await createCompany({
            variables: {
              ...dataUpdateCreateCompany(data),
            },
          });
        }

        if (response.data) {
          if (!logo.startsWith('https://')) {
            const logo1 = await urlTofile(logo, `logo${data.name}${new Date().toISOString()}`);

            const responseLOGO = await uploadLogo({
              variables: {
                file: logo1,
                id: isUpdatePage ? response.data.updateCompany?.toString() : response.data.createCompany.id?.toString(),
              },
            });
            if (background.startsWith('https://') && responseLOGO.data) {
              setLoader(false);
              setIsOpenModal(true);
            }
          }
          if (!background.startsWith('https://')) {
            const background1 = await urlTofile(background, `background${data.name}${new Date().toISOString()}`);

            const responseBACKGROUND = await uploadBackground({
              variables: {
                file: background1,
                id: isUpdatePage ? response.data.updateCompany?.toString() : response.data.createCompany.id?.toString(),
              },
            });
            if (responseBACKGROUND.data) {
              setLoader(false);
              setIsOpenModal(true);
            }
          }

          if (!banner.startsWith('https://')) {
            const banner1 = await urlTofile(banner, `banner${data.name}${new Date().toISOString()}`);

            const responseBANNER = await uploadBanner({
              variables: {
                file: banner1,
                id: isUpdatePage ? response.data.updateCompany?.toString() : response.data.createCompany.id?.toString(),
              },
            });
            if (responseBANNER.data) {
              setLoader(false);
              setIsOpenModal(true);
            }
          }

          if (logo.startsWith('https://') && background.startsWith('https://')) {
            setLoader(false);
            setIsOpenModal(true);
          }
          if (addPromo) {
            const responsePromo = await setCompanyPromo({
              variables: {
                publication: {
                  published_from: startEnd[0],
                  published_to: startEnd[1],
                },
                id: isUpdatePage ? response.data.updateCompany?.toString() : response.data.createCompany.id?.toString(),
              },
            });
          }
        }
      } catch (e) {
        catchError(setLoader, isUpdatePage, 'company', e);
      }
    }
  };
  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer>
        {errorOptions || error ? (
          <LoadingComponent goToEntity="companies" />
        ) : (
          <Form form={form} onFinish={onSubmit} style={{overflowX: 'scroll'}}>
            <div className={styles.divButton}>
              <Button type="primary" htmlType="submit" className={styles.customButton}>
                Save company
              </Button>
            </div>

            <div className={styles.borderTable}>
              <div className={styles.container}>
                <div className={styles.left}>
                  <div style={{display: 'flex'}}>
                    <UploadPhotos
                      hasCache={!!logoCache}
                      onChange={handleChangeImage(setLogo)}
                      onLoad={handleChangeImage(setLogoOp)}
                      image={logo}
                      redoImage={() => setLogo(logoCache)}
                      deleteImage={() => setLogo('')}
                      title={'Logo'}
                      aspectValue={1}
                      tooltipText={'Company logo.'}
                    />

                    <UploadPhotos
                      hasCache={!!backgroundCache}
                      onChange={handleChangeImage(setBackground)}
                      onLoad={handleChangeImage(setBackgroundOp)}
                      image={background}
                      redoImage={() => setBackground(backgroundCache)}
                      deleteImage={() => setBackground('')}
                      title={'Background'}
                      tooltipText={
                        'Photo to be used at the top of Company details page and as preview for Company cards.'
                      }
                      aspectValue={192 / 35}
                    />

                    <UploadPhotos
                      hasCache={!!bannerCache}
                      onChange={handleChangeImage(setBanner)}
                      onLoad={handleChangeImage(setBannerOp)}
                      image={banner}
                      redoImage={() => setItem(bannerCache)}
                      deleteImage={() => setBanner('')}
                      title={'Banner'}
                      aspectValue={2}
                      tooltipText={'Banner photo'}
                    />
                  </div>
                  <DetailsBlock
                    optionsData={{
                      companyCompanySizes: forSetCompanyOptionsData.companyCompanySizes,
                      // companyTags: forSetCompanyOptionsData.companyTags,
                      availableCities: cities,
                    }}
                  />
                  <div style={{height: '11.2rem'}} />
                  <TargetBlock
                    dataForSelectors={{
                      archetypes,
                      educationLevels,
                      profileInterests,
                      degrees,
                      grades,
                      achievements,
                      internationalExperiences,
                      activities,
                      languages,
                      workingExperiences,
                    }}
                  />
                </div>
                <div className={styles.right}>
                  <div style={{height: '11.2rem'}} />
                  <DetailsBlock2
                    richArea={{
                      en: {value: 'additional_info_en'},
                      fr: {value: 'additional_info_fr'},
                      nl: {value: 'additional_info_nl'},
                    }}
                    forCalendar={{
                      onCalendarChange: onCalendarChange,
                      startEnd: startEnd,
                    }}
                    promo={{addPromo, setAddPromo, activePromo}}
                    dataForSelectors={{
                      sectors: sectors,
                      emojis: emojis,
                    }}
                  />
                </div>
              </div>
              <div>
                <OpportunityEventLists id={id} />
              </div>
            </div>
          </Form>
        )}
        <SuccessModal
          onClose={() => closeModal('companies')}
          open={isOpenModal}
          messageKey={`Company was successful ${isUpdatePage ? 'updated' : 'created'}`}
        />
      </PageWithDrawer>
      {loader && (
        <Portal>
          <Loader loader={loader} />
        </Portal>
      )}
    </div>
  );
};

export default CompanyPage;
