import React, {FC, useEffect, useState} from 'react';
//Styling
import {useStyles} from '../styles';
import {GET_OPPORTUNITIES_IDNAME, GET_EVENTS_IDNAME} from 'requests/queries';
import {useQuery} from 'react-apollo';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {OpportunityActionsRedux} from 'store/opportunityDetails/actions';
import {EventActionsRedux} from 'store/eventDetails/actions';

interface Props {
  id: any;
}
const OpportunityEventLists: FC<Props> = ({id}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [opp, setopp] = useState([]);
  const [ev, setev] = useState([]);

  const {data: opportunities} = useQuery(GET_OPPORTUNITIES_IDNAME, {
    variables: {
      search: {company_id: id.toString()},
      limit: 10000,
      page: 1,
      order: undefined,
      orderDirection: undefined,
    },
    skip: !id,
  });
  const {data: events} = useQuery(GET_EVENTS_IDNAME, {
    variables: {
      search: {company_id: id.toString()},
      limit: 10000,
      page: 1,
      order: undefined,
      orderDirection: undefined,
    },
    skip: !id,
  });

  useEffect(() => {
    if (opportunities) {
      setopp(opportunities.opportunities.result);
    }
  }, [opportunities]);

  useEffect(() => {
    if (events) {
      setev(events.events.result);
    }
  }, [events]);
  const goToOpp = (id: string) => {
    dispatch(OpportunityActionsRedux.setEntityId(id));
    history.push(`/opportunities/details`, {id});
  };
  const goToEv = (id: string) => {
    dispatch(EventActionsRedux.setEntityId(id));
    history.push(`/events/details`, {id});
  };
  return (
    <div style={{display: 'flex', padding: '1rem 3rem 3rem'}}>
      <div className={styles.divLiked}>
        <div className={styles.titleLiked}>Opportunities</div>
        {opp?.map((item: any, id: number) => {
          return (
            <div key={id}>
              {id + 1}
              {'. '}
              <span
                style={{wordWrap: 'break-word', textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => goToOpp(item.id)}>
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.divLiked}>
        <div className={styles.titleLiked}>Events</div>
        {ev?.map((item: any, id: number) => {
          return (
            <div key={id}>
              {id + 1}
              {'. '}
              <span
                style={{wordWrap: 'break-word', textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => goToEv(item.id)}>
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OpportunityEventLists;
